import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/MdxPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Version 1, updated September 2021.`}</p>
    <h2>{`Introduction`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We are committed to safeguarding the privacy of our website visitors and service users.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We will ask you to consent to our use of cookies in accordance with the terms of this policy when you first visit our website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In this policy, "we", "us" and "our" refer to Spacecat Ltd. For more information about us, see Section 16.`}</p>
      </li>
    </ol>
    <h2>{`How we Use Your Personal Data`}</h2>
    <p>{`In this section we have set out the general categories of personal data that we may process, the purposes for which we may process personal data, the legal bases of the processing. In the case of personal data that we did not obtain directly from you, we have specified the source and specific categories of that data.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We may process data about your use of our website and services ("`}<em parentName="p">{`usage data`}</em>{`"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters ("`}<em parentName="p">{`notification data`}</em>{`"). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is consent.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may process information contained in or relating to any communication that you send to us ("`}<em parentName="p">{`correspondence data`}</em>{`"). The correspondence data may include the communication content and metadata associated with the communication. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In addition to the specific purposes for which we may process your personal data set out in this Section 2, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Please do not supply any other person's personal data to us, unless we prompt you to do so.`}</p>
      </li>
    </ol>
    <h2>{`Providing your personal data to others`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may disclose your personal data to our suppliers or subcontractors insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In addition to the specific disclosures of personal data set out in this Section 3, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.`}</p>
      </li>
    </ol>
    <h2>{`International transfers of your personal data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In this Section 4, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The hosting facilities for our website are situated in the United Kingdom, the European Economic Area (EEA), and the United States of America (USA). The European Commission has made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission, a copy of which you can obtain from `}<a parentName="p" {...{
            "href": "https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu%22%3Ehttps://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu"
          }}>{`the European Commission web site`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We make use of subcontractors in the United Kingdom, the European Economic Area (EEA), and the United States of America (USA). The European Commission has made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission, a copy of which can be obtained from `}<a parentName="p" {...{
            "href": "https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu"
          }}>{`the European Commission web site`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.`}</p>
      </li>
    </ol>
    <h2>{`Retaining and deleting personal data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`This Section 5 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We will retain your personal data as follows:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`usage data will be retained for a minimum period of 1 month, and for a maximum period of 10 years following its collection;`}</li>
          <li parentName="ol">{`account, service, notification, and correspondence data will be retained indefinitely while your account exists and for a maximum period of 1 month following a request to delete your account and your data.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.`}</p>
      </li>
    </ol>
    <h2>{`Security of personal data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We will take appropriate technical and organisational precautions to secure your personal data and to prevent the loss, misuse or alteration of your personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We will store all your personal data on secure servers, personal computers and mobile devices, and in secure manual record-keeping systems.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your password is stored in encrypted form on our servers using industry standard password encryption algorithms. Our staff do not have access to user passwords.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Data that is sent from your web browser to our web server, or from our web server to your web browser, will be protected using encryption technology.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You should ensure that your password is not susceptible to being guessed, whether by a person or a computer program. You are responsible for keeping the password you use for accessing our website confidential and we will not ask you for your password (except when you log in to our website).`}</p>
      </li>
    </ol>
    <h2>{`Amendments`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We may update this policy from time to time by publishing a new version on our website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You should check this page occasionally to ensure you are happy with any changes to this policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may notify you of changes to this policy by email or through the login process on our website.`}</p>
      </li>
    </ol>
    <h2>{`Your rights`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`the payment of a fee (currently fixed at GBP 10); and`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current address).`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may withhold personal information that you request to the extent permitted by law.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You may instruct us at any time not to process your personal information for marketing purposes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.`}</p>
      </li>
    </ol>
    <h2>{`Third party websites`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Our website includes hyperlinks to, and details of, third party websites.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We have no control over, and are not responsible for, the privacy policies and practices of third parties.`}</p>
      </li>
    </ol>
    <h2>{`Personal data of children`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Our website and services are targeted at persons over the age of 16.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If we have reason to believe that we hold personal data of a person under that age in our databases, we will delete that personal data.`}</p>
      </li>
    </ol>
    <h2>{`Updating information`}</h2>
    <ol>
      <li parentName="ol">{`Please let us know if the personal information that we hold about you needs to be corrected or updated.`}</li>
    </ol>
    <h2>{`About cookies`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.`}</p>
      </li>
    </ol>
    <h2>{`Cookies that we use`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We use cookies for the following purposes:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`authentication - we use cookies to identify you when you visit our website and as you navigate our website;`}</li>
          <li parentName="ol">{`status - we use cookies to help us to determine if you are logged into our website;`}</li>
          <li parentName="ol">{`personalisation - we use cookies to store information about your preferences and to personalise our website for you;`}</li>
          <li parentName="ol">{`security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;`}</li>
          <li parentName="ol">{`analysis - we use cookies to help us to analyse the use and performance of our website and services; and`}</li>
          <li parentName="ol">{`cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Cookies used by our service providers`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Our service providers use cookies and those cookies may be stored on your computer when you visit our website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We use `}<a parentName="p" {...{
            "href": "https://analytics.google.com"
          }}>{`Google Analytics`}</a>{` to analyse the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google's privacy policy is available at: `}<a parentName="p" {...{
            "href": ""
          }}>{`https://www.google.com/policies/privacy/`}</a>{`.`}</p>
      </li>
    </ol>
    <h2>{`Managing cookies`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://support.google.com/chrome/answer/95647?hl=en"
            }}>{`Chrome`}</a>{`;`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
            }}>{`Firefox`}</a>{`;`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "http://www.opera.com/help/tutorials/security/cookies/"
            }}>{`Opera`}</a>{`;`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
            }}>{`Internet Explorer`}</a>{`;`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://support.apple.com/kb/PH21411"
            }}>{`Safari`}</a>{`;`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
            }}>{`Edge`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Blocking all cookies will have a negative impact upon the usability of many websites.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you block cookies, you will not be able to use all the features on our website.`}</p>
      </li>
    </ol>
    <h2>{`Our details`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`This website is owned and operated by Spacecat Ltd.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We are registered in England and Wales under registration number 13166582, and our registered office is at Spacecat, Suite 12, 2nd Floor, Vantage Point, New England Road, Brighton, BN1 4GW.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Our principal place of business is at Spacecat, The Skiff, 30 Cheapside, Brighton, BN1 4GD, United Kingdom.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can contact us:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`by post, to our principal place of business above;`}</li>
          <li parentName="ol">{`by telephone, on the contact number published on our website from time to time; or`}</li>
          <li parentName="ol">{`by email, using the email address published on our website from time to time.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Representative within the European Union`}</h2>
    <ol>
      <li parentName="ol">{`Our representative within the European Union with respect to our obligations under data protection law is Dave Pereira-Gurnell, whom you can contact using the means provided in Clause 16.`}</li>
    </ol>
    <h2>{`Data protection officer`}</h2>
    <ol>
      <li parentName="ol">{`Our data protection officer is Dave Pereira-Gurnell, whom you can contact using the means provided in Clause 16.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      